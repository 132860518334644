export const Education = () => {
    return (
        <div className="page">
            <div className='navbar'>
                <a href="people"><h3>People</h3></a>
                <a href="education"><h3>Education</h3></a>
                <a href="experience"><h3>Experience</h3></a>
                <a href="teaching"><h3>Teaching</h3></a>
                <a href="research"><h3>Research</h3></a>
            </div>
            <h1 className="header">Education</h1>
            <h3>Ph.D</h3>
            <p>Indian Institute of Technology Delhi,1986</p>
            <h3>M.Tech</h3>
            <p>Indian Institute of Technology Delhi, 1983</p >
            <h3>B.Tech</h3>
            <p>Indian Institute of Technology Bombay,1980</p>
        </div >
    );
};