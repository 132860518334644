export default function Header() {
    return (
        <div className="head">
            <div className="head-name">
                <h1 style={{ color: "white" }}>Indus</h1>
                <h1 style={{ color: "red" }}>TANTRA</h1>
            </div>
            <h3>Start-Up at IIT Kanpur</h3>
            <h4>under the Faculty Entrepreneurship Program (FEP)</h4>
            <hr />
        </div>
    );
}