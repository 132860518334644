export const Contact = () => {
    return (
        <div className="page">
            <h1>Integration, Monitoring and Analytics of Large Systems & Processes</h1>
            <div>
                <div>
                    <h3>Contact Us</h3>
                    <p>Nalinaksh Vyas<br />
                        <a href="mailto:vyas@iitk.ac.in">vyas.nalinaksh@gmail.com</a><br />
                        +91 9956292801<br />
                        NL-103, MED<br />
                        IIT Kanpur, IN-208016
                    </p>
                </div>
            </div>
        </div >
    );
};