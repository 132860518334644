export const Teaching = () => {
    return (
        <div className="page">
            <div className='navbar'>
                <a href="people"><h3>People</h3></a>
                <a href="education"><h3>Education</h3></a>
                <a href="experience"><h3>Experience</h3></a>
                <a href="teaching"><h3>Teaching</h3></a>
                <a href="research"><h3>Research</h3></a>
            </div>
            <h1 className="header">Teaching</h1>
            <h3>New Courses Developed</h3>
            <p>Dynamics of Rotating Machinery<br />
                Railroad Vehicle Dynamics<br />
                Sensors and Instrumentation for Interactive Environments<br />
                Mechatronics<br />
                Measurement & Control through Virtual Instrumentation<br />
                Fabrionics</p>
            <h3>Other Courses</h3>
            <p>Dynamics & Control of Machinery<br />
                Fluid Mechanics & Rate Processes<br />
                Programming & Numerical Analysis<br />
                Design of Mechanical Systems<br />
                Mechanics of Solids<br />
                Engineering Graphics</p>
        </div>
    );
};