export const Experience = () => {
    return (
        <div className="page">
            <div className='navbar'>
                <a href="people"><h3>People</h3></a>
                <a href="education"><h3>Education</h3></a>
                <a href="experience"><h3>Experience</h3></a>
                <a href="teaching"><h3>Teaching</h3></a>
                <a href="research"><h3>Research</h3></a>
            </div>
            <h1 className="header">Experience</h1>
            <h3 style={{ color: "white" }}>...at IIT Kanpur, since 1987</h3>
            <h3>Visiting Faculty</h3>
            <p>Dept of Mechanical Engg, National Chung-Cheng University, Taiwan<br />
                Summer 1996<br />
                Dept.Genie Mechanique Developpement, INSA de Lyon, France
            </p>
            <h3>Indo US Fellow</h3>
            <p>Dept of Mechanical Engg<br />
                Virginia Tech, Blacksburg, Virginia, USA</p>
            <h3>Senior Scientific Officer</h3>
            <p>
                Department of Mechanical Engineering  <br />
                Indian Institute of Technology New Delhi <br />
                Feb 1986 - Dec 1987
            </p>
            <h3>Project Specialist</h3>
            <p>TATA 500 MW Expansion Project <br />
                TATA Thermal Power Station, Bombay<br />
                Jan 1981 - July 1981</p>
            <h3>Apprentice Engineer</h3>
            <p>Godrej & Boyce Manufacturing Company Pvt Ltd, Bombay<br />
                July 1980 - Jan 1981</p>
        </div>
    );
};